import "focus-visible";
import { inject } from "@vercel/analytics";
import { DateFormatter } from "./utils/dateFormatter";
import { CoordinatesGenerator } from "./utils/coordinatesGenerator";
import { SmoothScroll } from "./modules/scroll";
import { Menu } from "./modules/menu";
import { PageTransition } from "./modules/pageTransition";

class App {
  constructor() {
    this.initializeModules();
    this.updateUI();
  }

  initializeModules() {
    inject();
    new SmoothScroll();
    new Menu();
    PageTransition.init();
  }

  updateUI() {
    // Update date display
    const dateDisplay = document.getElementById("date-display");
    if (dateDisplay) {
      dateDisplay.textContent = DateFormatter.formatDate();
    }

    // Update coordinates display
    const coordinatesDisplay = document.getElementById("coordinates-display");
    if (coordinatesDisplay) {
      const coords = CoordinatesGenerator.getRandomCoordinates();
      coordinatesDisplay.innerHTML = CoordinatesGenerator.formatCoordinates(
        coords.latitude,
        coords.longitude,
      );
    }

    // Update year display
    const yearDisplay = document.getElementById("currentYear");
    if (yearDisplay) {
      yearDisplay.textContent = DateFormatter.getCurrentYear();
    }
  }
}

// Initialize the application
document.addEventListener("DOMContentLoaded", () => {
  new App();
});
