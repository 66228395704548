import { ANIMATION_CONFIG } from "../config/constants";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import barba from "@barba/core";

export const PageTransition = {
  menuInstance: null,

  setMenuInstance(instance) {
    this.menuInstance = instance;
  },
  async init() {
    gsap.registerPlugin(ScrollTrigger);

    barba.init({
      transitions: [
        {
          name: "opacity-transition",
          async leave(data) {
            // Если меню открыто, сначала закрываем его
            if (
              this.menuInstance &&
              document.querySelector(".header").classList.contains("active-menu")
            ) {
              await new Promise((resolve) => {
                const tl = gsap.timeline({
                  onComplete: resolve,
                });

                tl.to(".main-menu", {
                  duration: 0.7,
                  y: -482,
                  ease: ANIMATION_CONFIG.MENU.EASE,
                }).to(
                  ".overlay-menu",
                  {
                    autoAlpha: 0,
                    zIndex: -10,
                  },
                  0.4,
                );

                document.querySelector(".header").classList.remove("active-menu");
              });
            }

            // Затем выполняем переход страницы
            await gsap.to(data.current.container, {
              opacity: ANIMATION_CONFIG.TRANSITION.OPACITY,
              duration: ANIMATION_CONFIG.TRANSITION.DURATION,
            });
          },
          async enter(data) {
            await gsap.from(data.next.container, {
              opacity: ANIMATION_CONFIG.TRANSITION.OPACITY,
              duration: ANIMATION_CONFIG.TRANSITION.DURATION,
            });
          },
        },
      ],
      views: [
        {
          namespace: "all",
          afterEnter() {
            this.initPageScripts();
          },
        },
      ],
    });

    this.initHooks();
  },

  initPageScripts() {
    console.log("Initializing page-specific scripts");
    Splitting();
    ScrollOut({ threshold: 0.5, once: false });
  },

  initHooks() {
    barba.hooks.before(() => console.log("Transition starting..."));
    barba.hooks.enter(() => console.log("Entering new page..."));
    barba.hooks.leave(() => console.log("Leaving current page..."));
  },
};
